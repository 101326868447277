import React, { useEffect, useState } from "react";
import {
  FilterOption,
  filterOptionDescription,
} from "../../models/globals/enum/filterOption";
import { SearchParameters } from "../../models/searchParameters";
import { Collapse } from "reactstrap";

interface IFilterListProperties {
  fetchData: (searchParams: SearchParameters) => Promise<boolean>;
  clearResults: () => void;
  showNotify: (header: string, body: string) => void;
}

const FilterList: React.FC<IFilterListProperties> = (
  props: IFilterListProperties
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filterlist, setFilterlist] = useState<any[]>([]);
  const [isGastouder, setIsGastouder] = useState<boolean>(true);
  const [isGastouderBureau, setIsGastouderBureau] = useState<boolean>(true);
  const [isKinderdagverblijf, setIsKinderdagverblijf] = useState<boolean>(true);
  const [isBuitenschoolseOpvang, setIsBuitenschoolseOpvang] =
    useState<boolean>(true);
  const [hasReviews, setHasReviews] = useState<boolean>(false);
  const [isVoorschoolseEducatie, setIsVoorschoolseEducatie] =
    useState<boolean>(false);
  const [isOuderschapsParticipatie, setIsOuderschapsParticipatie] =
    useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState("Naam");
  const [searchText, setSearchText] = useState("");
  const isSearchNumber =
    selectedOption === "LRKNummer" ||
    selectedOption === "KVKNummer" ||
    selectedOption === "Telefoonnummer"
      ? true
      : false;
  const isSearchText = !isSearchNumber;

  useEffect(() => {
    // Load the filters from local storage
    let filterlistFromStorage = localStorage.getItem("filterlist");
    if (filterlistFromStorage !== null) {
      setFilterlist(JSON.parse(filterlistFromStorage));
    }

    let checkboxFilterlistFromStorage = localStorage.getItem("filterData");
    if (checkboxFilterlistFromStorage !== null) {
      let filterData = JSON.parse(checkboxFilterlistFromStorage);
      setIsGastouder(filterData.isGastouder);
      setIsGastouderBureau(filterData.isGastouderBureau);
      setIsKinderdagverblijf(filterData.isKinderdagverblijf);
      setIsBuitenschoolseOpvang(filterData.isBuitenschoolseOpvang);
      setIsVoorschoolseEducatie(filterData.isVoorschoolseEducatie);
      setIsOuderschapsParticipatie(filterData.isOuderschapsParticipatie);
      setHasReviews(filterData.hasReviews);
    }
  }, []);

  const clearFilters = () => {
    setFilterlist([]);
    localStorage.removeItem("filterlist");
    localStorage.removeItem("filterData");
    setSearchText("");
    props.clearResults();
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value);
  };

  const showFiltersToggle = () => {
    setShowFilters(!showFilters);
    console.log("showFilters: " + showFilters);
  };

  const addFilter = () => {
    if (searchText === "") {
      props.showNotify(
        "Vul een zoekterm in",
        `Geen zoekterm ingevuld voor filter ${selectedOption}`
      );
      return;
    }
    switch (selectedOption) {
      case filterOptionDescription[FilterOption.Naam]:
      case filterOptionDescription[FilterOption.StraatHuisnummer]:
      // case filterOptionDescription[FilterOption.Regio]: //TODO: Fix region, disabled for now
      case filterOptionDescription[FilterOption.LRKNummer]:
      case filterOptionDescription[FilterOption.KVKNummer]:
      case filterOptionDescription[FilterOption.Email]:
      case filterOptionDescription[FilterOption.Telefoonnummer]:
      case filterOptionDescription[FilterOption.Website]:
        if (
          filterlist.find((item) => item.category === selectedOption) !==
          undefined
        ) {
          props.showNotify(
            `Filter bestaat al - ${selectedOption}`,
            `Er kan slechts 1 filter op de categorie ${selectedOption} toegevoegd worden`
          );
          return;
        }
        break;
      case filterOptionDescription[FilterOption.Postcode]:
      case filterOptionDescription[FilterOption.Stad]:
        if (
          filterlist.filter((item) => item.category === selectedOption)
            .length >= 5
        ) {
          props.showNotify(
            `Filter aantal te groot - ${selectedOption}`,
            `Er kunnen maximaal 5 filters op de categorie ${selectedOption} toegevoegd worden`
          );
          return;
        }
        break;
    }
    filterlist.push({
      id: filterlist.length + 1,
      value: `${searchText}`,
      category: `${selectedOption}`,
    });
    setFilterlist([...filterlist]);

    // save the filters in local storage
    localStorage.setItem("filterlist", JSON.stringify(filterlist));

    // clear the search text
    setSearchText("");
  };

  const removeFilter = (
    itemId: any
  ): React.MouseEventHandler<HTMLButtonElement> | undefined => {
    let itemToRemove = filterlist.find((item) => item.id === itemId);
    if (filterlist !== undefined && itemToRemove !== undefined) {
      let index = filterlist.indexOf(itemToRemove);
      filterlist.splice(index, 1);
      setFilterlist([...filterlist]);

      // save the filters in local storage
      localStorage.setItem("filterlist", JSON.stringify(filterlist));

      props.showNotify(
        "Filter verwijderd",
        `Filter ${itemToRemove.category} - ${itemToRemove.value} verwijderd`
      );
    }
    return;
  };

  const fetchSearchData = async () => {
    setIsLoading(true);
    props.clearResults();

    let searchParams: SearchParameters = new SearchParameters();
    searchParams.isGastouder = isGastouder;
    searchParams.isGastouderBureau = isGastouderBureau;
    searchParams.isKinderdagverblijf = isKinderdagverblijf;
    searchParams.isBuitenschoolseOpvang = isBuitenschoolseOpvang;
    searchParams.isOuderschapsParticipatie = isOuderschapsParticipatie;
    searchParams.isVoorschoolseEducatie = isVoorschoolseEducatie;
    searchParams.hasReviews = hasReviews;
    for (const [key, value] of Object.entries(filterlist)) {
      switch (value.category) {
        case filterOptionDescription[FilterOption.Naam]:
          searchParams.name = value.value;
          break;
        case filterOptionDescription[FilterOption.StraatHuisnummer]:
          // Check if the value contains a number, if so split the string into street and house number
          if (/\d/.test(value.value)) {
            const splitIndex = value.value.lastIndexOf(" ");
            const street = value.value.slice(0, splitIndex);
            const houseNumber = value.value.slice(splitIndex + 1);
            searchParams.street = street;
            searchParams.houseNumber = houseNumber;
          } else {
            searchParams.street = value.value;
          }
          break;
        case filterOptionDescription[FilterOption.Postcode]:
          searchParams.postalCode = value.value;
          break;
        case filterOptionDescription[FilterOption.Stad]:
          searchParams.city = value.value;
          break;
        // case "Regio": //TODO: Fix region, disabled for now
        //   searchParams.region = value.value;
        //   break;
        case filterOptionDescription[FilterOption.LRKNummer]:
          searchParams.lrkNumber = value.value;
          break;
        case filterOptionDescription[FilterOption.KVKNummer]:
          searchParams.kvkNumber = value.value;
          break;
        case filterOptionDescription[FilterOption.Email]:
          searchParams.email = value.value;
          break;
        case filterOptionDescription[FilterOption.Telefoonnummer]:
          searchParams.phoneNumber = value.value;
          break;
        case filterOptionDescription[FilterOption.Website]:
          searchParams.website = value.value;
          break;
      }
    }
    await props.fetchData(searchParams);

    setIsLoading(false);
  };

  function toggleCheckboxFilter(value: string): void {
    const filterData = {
      isGastouder: isGastouder,
      isGastouderBureau: isGastouderBureau,
      isKinderdagverblijf: isKinderdagverblijf,
      isBuitenschoolseOpvang: isBuitenschoolseOpvang,
      isVoorschoolseEducatie: isVoorschoolseEducatie,
      isOuderschapsParticipatie: isOuderschapsParticipatie,
      hasReviews: hasReviews,
    };

    switch (value) {
      case "isGastouder":
        setIsGastouder(!isGastouder);
        filterData.isGastouder = !isGastouder;
        break;
      case "isGastouderBureau":
        setIsGastouderBureau(!isGastouderBureau);
        filterData.isGastouderBureau = !isGastouderBureau;
        break;
      case "isKinderdagverblijf":
        setIsKinderdagverblijf(!isKinderdagverblijf);
        filterData.isKinderdagverblijf = !isKinderdagverblijf;
        break;
      case "isBuitenschoolseOpvang":
        setIsBuitenschoolseOpvang(!isBuitenschoolseOpvang);
        filterData.isBuitenschoolseOpvang = !isBuitenschoolseOpvang;
        break;
      case "isVoorschoolseEducatie":
        setIsVoorschoolseEducatie(!isVoorschoolseEducatie);
        filterData.isVoorschoolseEducatie = !isVoorschoolseEducatie;
        break;
      case "isOuderschapsParticipatie":
        setIsOuderschapsParticipatie(!isOuderschapsParticipatie);
        filterData.isOuderschapsParticipatie = !isOuderschapsParticipatie;
        break;
      case "hasReviews":
        setHasReviews(!hasReviews);
        filterData.hasReviews = !hasReviews;
        break;
    }
    localStorage.setItem("filterData", JSON.stringify(filterData));
  }

  return (
    <div className="mt-4 mb-4">
      <h2>Zoeken</h2>

      <Collapse
        className="justify-content-end slide-in-left"
        isOpen={!showFilters}
        navbar
        expand="false"
      >
        <div className="card p-3" id="start-search">
          <p>
            Voeg een of meerdere filters toe om te beginnen met zoeken. <br />
          </p>
          <div className="mb-2">
            <label className="form-label">Filters</label>
            <br />
            <select
              className="form-select"
              id="selectFilter"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              {Object.values(filterOptionDescription).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {isSearchText && (
              <input
                type="text"
                className="form-control"
                placeholder={`Zoeken op ${selectedOption}`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchTextFilter"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addFilter();
                  }
                }}
              />
            )}

            {isSearchNumber && (
              <input
                type="tel"
                className="form-control"
                placeholder={`Zoeken op ${selectedOption}`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchTextFilter"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
              />
            )}

            <div className="form-group mt-2">
              {/* <label className="form-label">Type</label> <br /> */}
              <div className="row">
                <div className="col-6">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isGastouder}
                      onChange={() => toggleCheckboxFilter("isGastouder")}
                      id="gastouderCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="gastouderCheckbox"
                    >
                      Gastouder
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isGastouderBureau}
                      onChange={() => toggleCheckboxFilter("isGastouderBureau")}
                      id="gastouderBureauCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="gastouderBureauCheckbox"
                    >
                      Gastouderbureau
                    </label>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isKinderdagverblijf}
                      onChange={() =>
                        toggleCheckboxFilter("isKinderdagverblijf")
                      }
                      id="kinderdagverblijfCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="kinderdagverblijfCheckbox"
                    >
                      Kinderdagverblijf
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isBuitenschoolseOpvang}
                      onChange={() =>
                        toggleCheckboxFilter("isBuitenschoolseOpvang")
                      }
                      id="buitenschoolseOpvangCheckbox"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="buitenschoolseOpvangCheckbox"
                    >
                      Buitenschoolse Opvang
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group mt-2">
                <label className="form-label">Extra's</label> <br />
                <div className="row">
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isVoorschoolseEducatieCheckbox"
                        checked={isVoorschoolseEducatie}
                        onChange={() =>
                          toggleCheckboxFilter("isVoorschoolseEducatie")
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isVoorschoolseEducatieCheckbox"
                      >
                        Voorschoolse Educatie
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isOuderschapsParticipatieCheckbox"
                        checked={isOuderschapsParticipatie}
                        onChange={() =>
                          toggleCheckboxFilter("isOuderschapsParticipatie")
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="isOuderschapsParticipatieCheckbox"
                      >
                        Ouderschaps Participatie
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="showReviewsCheckbox"
                        checked={hasReviews}
                        onChange={() => toggleCheckboxFilter("hasReviews")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="showReviewsCheckbox"
                      >
                        Met reviews
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="mb-3">
            <div className="mt-1">
              <button
                className="btn button-30"
                type="button"
                onClick={addFilter}
                disabled={searchText === ""}
              >
                <i className="fa fa-plus button-icon"></i>
                Filter
              </button>
              <button
                className="btn button-30 btn-outline-danger float-end"
                type="button"
                onClick={clearFilters}
                disabled={filterlist.length === 0}
              >
                <i className="fa fa-times button-icon"></i>
                Wissen
              </button>

              <br />
              <div className="mt-3">
                <button
                  className="btn button-30 btn-outline-success"
                  type="button"
                  onClick={fetchSearchData}
                  disabled={filterlist.length === 0}
                >
                  {!isLoading && <i className="fa fa-search button-icon"></i>}
                  {isLoading && (
                    <i
                      className="spinner-border spinner-border-sm button-icon"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Zoeken
                </button>

                <span className="float-end">
                  {!showFilters && (
                    <button
                      type="button"
                      className="btn button-30 btn-outline-primary"
                      onClick={showFiltersToggle}
                      title="Verberg filters"
                    >
                      <i className="fa fa-chevron-left "></i>
                    </button>
                  )}
                </span>
              </div>
            </div>
          </div>

          {/* Active filter list */}
          <span>
            <label className="form-label">Actieve filters</label> <br />
          </span>
          <div className="table-responsive">
            <table className="filter-table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filterlist.map((item) => (
                  <tr key={item.id}>
                    <td className="filterCategory">{item.category}</td>
                    <td className="filterValue">{item.value}</td>
                    <div>
                      <button
                        className="btn btn-outline-danger float-end"
                        type="button"
                        id="button-addon2"
                        onClick={() => removeFilter(item.id)}
                      >
                        X
                      </button>
                    </div>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Collapse>

      {showFilters && (
        <button
          type="button"
          className="btn button-30 btn-outline-primary"
          onClick={showFiltersToggle}
          title="Toon filters"
        >
          <i className="fa fa-chevron-right"></i>
        </button>
      )}
    </div>
  );
};

export default FilterList;
