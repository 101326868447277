import { ICompany } from "../../models/ICompany";
import { useEffect, useState } from "react";
import { IReview } from "../../models/IReview";

interface ReviewsInfoCardProps {
  paramCompany: ICompany;
}

const ReviewsInfoCard: React.FC<ReviewsInfoCardProps> = ({ paramCompany }) => {
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [ratingTotal, setRatingTotal] = useState<number>(0);

  useEffect(() => {
    setRatingTotal(paramCompany?.ratingTotal ?? 0);
    setReviews(paramCompany?.reviews);
  }, [paramCompany, reviews]);

  const navigateToReviewAdd = () => {
    const element = document.getElementById("new-review");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="container card p-3">
        <div className="row">
          <div className="col-lg-6">
            <div className="stars_infocard">
              {ratingTotal >= 1 ? (
                <i className="fa fa-star text-warning"></i>
              ) : (
                ""
              )}
              {ratingTotal >= 2 ? (
                <i className="fa fa-star text-warning"></i>
              ) : (
                ""
              )}
              {ratingTotal >= 3 ? (
                <i className="fa fa-star text-warning"></i>
              ) : (
                ""
              )}
              {ratingTotal >= 4 ? (
                <i className="fa fa-star text-warning"></i>
              ) : (
                ""
              )}
              {ratingTotal >= 5 ? (
                <i className="fa fa-star text-warning"></i>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="text-center mt-3">
              <h5 className="card-title">Gemiddelde score</h5>
              <p className="card-text">{ratingTotal.toFixed(1)} / 5</p>
            </div>
          </div>
        </div>
      </div>

      <div className="float-center pt-3">
        <button
          type="submit"
          className="btn button-30 btn-lg btn-outline-success"
          aria-label="Zoeken"
          onClick={navigateToReviewAdd}
        >
          <i className="fa fa-plus button-icon"></i>
          Schrijf een review
        </button>
      </div>
    </div>
  );
};

export default ReviewsInfoCard;
