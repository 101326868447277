import React from "react";
import image_play from "../../assets/img/blocks-play.jpg";

const OverOns: React.FC = () => {
  return (
    <div>
      <div className="row align-items-center g-lg-5 g-sm-5 mb-5 rounded-bottom">
        <div className="text-center text-lg-start pt-5">
          <h1 className="hero-title hero-content">Over ons</h1>
        </div>

        <div className="herohead-35 hero-overons-bg" />

        <div className="after-hero-mobile after-hero">
          <div className="row align-items-center g-lg-5 py-5">
            <h2 className="text-center">
              Reviews geschreven door ouders zoals jij
            </h2>

            <p className="text-center">
              Wij begrijpen hoe belangrijk het is om de juiste kinderopvang voor
              je kind te vinden. <br />
              Als jonge ouders weten we hoe overweldigend het proces van het
              selecteren van een kinderdagverblijf kan zijn. <br />
              Uiteraard weten we ook hoe frustrerend het kan zijn als dingen
              niet gaan zoals gepland.
            </p>
            <p className="text-center">
              Onze beoordelingen zijn geschreven door ouders zoals jij, die uit
              de eerste hand ervaring hebben met de desbetreffende
              kinderdagverblijven. <br />
              We streven ernaar een platform te bieden waar ouders hun eerlijke
              mening kunnen delen en anderen kunnen helpen weloverwogen
              beslissingen te nemen over waar ze hun kleintjes inschrijven.
            </p>

            <h2 className="text-center pt-5 pb-2">
              Het digitale platform voor reviews in de kinderopvang
            </h2>
            <p className="text-center">
              Een centrale plaats waar alle kinderopvang instanties te vinden
              zijn.
              <br />
              We willen andere ouders van eerlijke beoordelingen en nauwkeurige
              informatie voorzien over kinderdagverblijven in hun omgeving.{" "}
              <br />
              <br />
              Door onze ervaringen met anderen te delen, kan het vinden van de
              juiste kinderopvang net een beetje gemakkelijker worden.
              <br />
              Helpen met het zoeken naar de beste opvang, zodat kinderen in een
              vertrouwde omgeving kunnen spelen. Dat is ons doel!
            </p>

            <div className="text-center p-3">
              <img
                className="img-fluid"
                src={image_play}
                alt="zodat de kinderen lekker kunnen spelen"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverOns;
